function __menu__(){

    $(".toggle-menu").on("click", function (e) {
        e.preventDefault();
        $('<div class="mask-overlay">').hide().appendTo("body").fadeIn("fast"),
          $("html")
            .addClass("no-scroll sidebar-open")
            .height(e.innerHeight + "px"),
          $("#wpadminbar").length
            ? $(".sidebar-open .site-nav").css("top", "46px")
            : $(".sidebar-open .site-nav").css("top", "0");
      });
      $(".close-menu, .mask-overlay").on("click", function (e) {
        e.preventDefault(),
          $("html").removeClass("no-scroll sidebar-open").height("auto"),
          $(".mask-overlay").remove();
      });
    
      $(".site-main-menu")
        .children("li")
        .click(function (e) {
          e.preventDefault(),
            $("html").removeClass("no-scroll sidebar-open").height("auto"),
            $(".mask-overlay").remove();
        });

}